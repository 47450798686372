import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import LoginBackground from '../assets/login-background.png';
import { AuthLayout } from '../components/layouts/AuthLayout';
import { Button } from '../components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../components/ui/form';
import { Input } from '../components/ui/input';
import Spinner from '../components/ui/spinner';
import { authService } from '../services/api.service';

const forgotPasswordSchema = z.object({
  email: z.string().email('Invalid email address'),
});

type ForgotPasswordFormValues = z.infer<typeof forgotPasswordSchema>;

interface ForgotPasswordResponse {
  flag: boolean;
  message: string;
}

const requestPasswordReset = async (data: ForgotPasswordFormValues) => {
  const response = await authService.forgotPassword(data.email);
  if (!response.data.flag) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const ForgotPassword = () => {
  const form = useForm<ForgotPasswordFormValues>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  });

  const navigate = useNavigate();
  const mutation = useMutation<ForgotPasswordResponse, Error, ForgotPasswordFormValues>({
    mutationFn: requestPasswordReset,
    onSuccess: (response) => {
      toast.success(response.message);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to send reset link';
      toast.error(errorMessage);
    },
  });

  const onSubmit = async (data: ForgotPasswordFormValues) => {
    const result = await form.trigger();
    if (result) {
      mutation.mutate(data);
    }
  };

  return (
    <AuthLayout
      backgroundImage={LoginBackground}
      title="Forgot Password?"
      subtitle="Enter your email to reset your password"
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter your email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="w-full"
            disabled={mutation.isPending}
          >
            {mutation.isPending ? (
              <div className="flex items-center justify-center gap-2">
                <Spinner />
              </div>
            ) : (
              "Send Reset Link"
            )}
          </Button>

          <div className="text-center text-sm text-black font-medium">
            Remember your password?{' '}
            <Link
              to="/login"
              className="font-normal text-black hover:text-gray-900 hover:underline"
            >
              Login
            </Link>
          </div>
        </form>
      </Form>
    </AuthLayout>
  );
}; 