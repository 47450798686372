import { useMutation } from '@tanstack/react-query';
import { CheckIcon } from 'lucide-react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardHeader } from '../components/ui/card';
import Spinner from '../components/ui/spinner';
import { authService } from '../services/api.service';

const ResendConfirmEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const mutation = useMutation({
    mutationFn: () => {
      if (!email) throw new Error('Email is required');
      return authService.resendConfirmationEmail(email);
    },
    onSuccess: (response) => {
      if (response.data.flag) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        toast.error(response.data.message);
      }
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to resend confirmation email';
      toast.error(errorMessage);
    },
  });

  if (!email) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background p-4">
        <Card className="max-w-lg w-full">
          <CardContent className="space-y-6 pt-6">
            <p className="text-muted-foreground text-center">
              Invalid request. Missing email address.
            </p>
            <Button
              onClick={() => navigate('/login')}
              className="w-full"
            >
              Return to Login
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <Card className="max-w-lg w-full">
        <CardHeader>
          <h2 className="text-2xl font-semibold text-center flex items-center justify-center gap-4 fade-in">
            {mutation.isSuccess && mutation.data?.data.flag ? (
              <>
                Email Successfully Sent <CheckIcon className="w-6 text-white p-0.5 h-6 bg-green-500 rounded-full" />
              </>
            ) : (
              "Resend Confirmation Email"
            )}
          </h2>
        </CardHeader>
        <CardContent className="space-y-6">
          <p className="text-muted-foreground text-center fade-in">
            {mutation.isSuccess && mutation.data?.data.flag 
              ? "Please check your email inbox for the confirmation link."
              : "Click the button below to resend a confirmation email."}
          </p>
          <Button
            onClick={() => mutation.mutate()}
            className="w-full mb-3"
            disabled={mutation.isPending}
          >
            {mutation.isPending ? (
              <div className="flex items-center justify-center gap-2">
                <Spinner />
              </div>
            ) : (
              "Resend Confirmation Email"
            )}
          </Button>
          <Button
            onClick={() => navigate('/login')}
            className="w-full"
            variant="outline"
          >
            Return to Login
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResendConfirmEmail; 