
interface DashboardIconProps {
    
}
 
const DashboardIcon: React.FC<DashboardIconProps> = () => {
    return ( 

        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5 1.57935H2.33333C1.8731 1.57935 1.5 1.95244 1.5 2.41268V6.57935C1.5 7.03958 1.8731 7.41268 2.33333 7.41268H6.5C6.96024 7.41268 7.33333 7.03958 7.33333 6.57935V2.41268C7.33333 1.95244 6.96024 1.57935 6.5 1.57935Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6667 1.57935H11.5C11.0398 1.57935 10.6667 1.95244 10.6667 2.41268V6.57935C10.6667 7.03958 11.0398 7.41268 11.5 7.41268H15.6667C16.1269 7.41268 16.5 7.03958 16.5 6.57935V2.41268C16.5 1.95244 16.1269 1.57935 15.6667 1.57935Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6667 10.746H11.5C11.0398 10.746 10.6667 11.1191 10.6667 11.5793V15.746C10.6667 16.2062 11.0398 16.5793 11.5 16.5793H15.6667C16.1269 16.5793 16.5 16.2062 16.5 15.746V11.5793C16.5 11.1191 16.1269 10.746 15.6667 10.746Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.5 10.746H2.33333C1.8731 10.746 1.5 11.1191 1.5 11.5793V15.746C1.5 16.2062 1.8731 16.5793 2.33333 16.5793H6.5C6.96024 16.5793 7.33333 16.2062 7.33333 15.746V11.5793C7.33333 11.1191 6.96024 10.746 6.5 10.746Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    );

}
 
export default DashboardIcon;