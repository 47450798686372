import DashboardLogo from "../assets/voltox-dashboard-logo.png";
import type { RouteId } from "../config/routes";
import { routes as routesConfig } from "../config/routes";
import { cn } from "../lib/utils";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";

interface Route {
  id: RouteId;
  title: string;
  icon: React.ElementType;
  component: () => JSX.Element;
}

interface SidebarProps {
  routes?: typeof routesConfig;
  activeItem: RouteId;
  onItemClick: (id: RouteId) => void;
  className?: string;
}

export const Sidebar = ({
  routes = routesConfig,
  activeItem,
  onItemClick,
  className,
}: SidebarProps) => {
  const mainRoutes = routes.filter(route => route.id !== 'settings');
  const settingsRoute = routes.find(route => route.id === 'settings');

  return (
    <Card className={cn(
      "fixed left-0 h-screen w-64 rounded-none border-r border-gray-200 flex flex-col",
      className
    )}>
      <CardHeader className="px-11 pt-[76px] pb-10">
        <img src={DashboardLogo} alt="logo" />
      </CardHeader>
      
      <CardContent className="px-4 py-4 flex-1">
        <nav className="space-y-2">
          {mainRoutes.map((route: Route) => {
            const Icon = route.icon;
            return (
              <Button
                key={route.id}
                variant={activeItem === route.id ? "secondary" : "ghost"}
                className={`w-full justify-start gap-2 h-[70px] font-normal text-sm ${
                  activeItem === route.id ? "rounded-xl" : "rounded-none transition-all duration-200"
                } ${route.id ?? ''}`}
                onClick={() => onItemClick(route.id)}
              >
                <Icon className="mr-2 h-4 w-4" />
                {route.title}
              </Button>
            );
          })}
        </nav>
      </CardContent>

      {settingsRoute && (
        <div className="px-4 py-4 mt-auto">
          <Button
            variant={activeItem === 'settings' ? "secondary" : "ghost"}
            className={`w-full justify-start border-t gap-2 h-[70px] font-normal text-sm ${
              activeItem === 'settings' ? "rounded-xl" : "rounded-none transition-all duration-200"
            }`}
            onClick={() => onItemClick('settings')}
          >
            <settingsRoute.icon />
            {settingsRoute.title}
          </Button>
        </div>
      )}
    </Card>
  );
}; 