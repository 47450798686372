import { ChevronDown, LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useUser } from '../contexts/UserContext';
import { authService } from "../services/api.service";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "./ui/dropdown-menu";

export function UserProfileDropdown() {
  const { user } = useUser();
  console.log(user, "Arian")
  const navigate = useNavigate();

  const onLogout = () => {
    authService.logout();
    navigate('/login');
  };
  
  // Create initials for avatar fallback
  const initials = user?.name
    ? user.name
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase()
    : '??';


  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center space-x-2 hover:bg-accent hover:text-accent-foreground rounded-md px-2 py-1 outline-none">
        <Avatar className="h-8 w-8">
          <AvatarImage src="/avatars/01.png" alt={user?.name} />
          <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col text-left">
          <span className="text-sm font-medium">{user?.name || 'User'}</span>
          <span className="text-xs text-muted-foreground">{user?.email || 'loading...'}</span>
        </div>
        <ChevronDown className="h-4 w-4 text-muted-foreground" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuItem onClick={onLogout} className="text-red-600">
          <LogOut className="mr-2 h-4 w-4" />
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
} 