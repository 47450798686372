import axios from "axios";
import { format } from "date-fns";

interface AgeVerification {
  is_verified: boolean;
  predicted_age: number;
  documented_age: number;
}

export interface VerificationData {
  request_id: string;
  age: number;
  gender: string;
  race: string;
  emotion: string;
  is_face_visible: number;
  status: string;
  is_spoof: number;
  age_verification: AgeVerification | null;
  created_at?: string;
}

export interface FormattedVerificationData extends Omit<VerificationData, 'is_face_visible' | 'is_spoof' | 'age' | 'age_verification'> {
  is_face_visible: string;
  is_spoof: string;
  age: string;
  age_verification: string;
}

export const verificationService = {
  async getVerificationHistory(clientId: string): Promise<FormattedVerificationData[]> {
    try {
      const response = await axios.get<VerificationData[]>(`${process.env.REACT_APP_AI_API_URL}/get-client-data`, {
        params: { client_id: clientId }
      });

      return response.data.map(this.formatVerificationData);
    } catch (error) {
      console.error('Error fetching verification history:', error);
      throw new Error('Failed to fetch verification history');
    }
  },

  formatVerificationData(data: VerificationData): FormattedVerificationData {
    const formatAgeVerification = (ageVerification: AgeVerification | null): string => {
      if (!ageVerification) return 'N/A';
      return `${ageVerification.is_verified ? 'Verified' : 'Not Verified'} (Predicted: ${ageVerification.predicted_age}, Documented: ${ageVerification.documented_age})`;
    };

    return {
      ...data,
      is_face_visible: data.is_face_visible ? "Yes" : "No",
      is_spoof: data.is_spoof ? "Yes" : "No",
      age: data.age.toFixed(2),
      age_verification: formatAgeVerification(data.age_verification),
      created_at: data.created_at ? format(new Date(data.created_at), 'PPpp') : 'N/A',
      status: data.status.toUpperCase(),
    };
  }
}; 