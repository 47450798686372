import { format } from "date-fns";
import { CalendarIcon, FilterIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { cn } from "../../lib/utils";
import { verificationService, type FormattedVerificationData } from "../../services/verification.service";
import { DataTable } from "../DataTable";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Card, CardContent } from "../ui/card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export const VerificationHistory = () => {
  const { user } = useUser();
  const [verificationData, setVerificationData] = useState<FormattedVerificationData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [date, setDate] = useState<Date>();
  const [clientType, setClientType] = useState<string>("");
  const [clientStatus, setClientStatus] = useState<string>("");

  const columns = [
    {
      header: "Request ID",
      accessorKey: "request_id",
    },
    {
      header: "Age",
      accessorKey: "age",
    },
    {
      header: "Gender",
      accessorKey: "gender",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Face Visible",
      accessorKey: "is_face_visible",
    },
    {
      header: "Spoof Detected",
      accessorKey: "is_spoof",
    },
    {
      header: "Age Verification",
      accessorKey: "age_verification",
    },
    {
      header: "Date",
      accessorKey: "created_at",
    },
  ];

  // Reset filters
  const handleResetFilters = () => {
    setDate(undefined);
    setClientType("");
    setClientStatus("");
  };

  useEffect(() => {
    const fetchVerificationHistory = async () => {
      if (!user?.userId) {
        setError("No client ID available");
        setIsLoading(false);
        return;
      }

      try {
        const data = await verificationService.getVerificationHistory(user.userId);
        setVerificationData(data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch verification history");
        console.error("Error fetching verification history:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVerificationHistory();
  }, [user?.userId]);

  return (
    <div className="space-y-4 fade-in">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-medium tracking-tight text-[#202224] font-['Nunito_Sans']">
          Age Verification Profiles
        </h2>
      </div>

      {/* Filter Section */}
      <div className="flex items-center gap-2 p-4 bg-white rounded-lg border">
        <div className="flex items-center gap-2">
          <FilterIcon className="h-5 w-5 text-gray-500" />
          <span className="text-sm font-medium">Filter By</span>
        </div>

        {/* Date Filter */}
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                "justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date ? format(date, "PPP") : "Date"}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              initialFocus
            />
          </PopoverContent>
        </Popover>

        {/* Client Type Filter */}
        <Select value={clientType} onValueChange={setClientType}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Client Type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="individual">Individual</SelectItem>
            <SelectItem value="business">Business</SelectItem>
            <SelectItem value="enterprise">Enterprise</SelectItem>
          </SelectContent>
        </Select>

        {/* Client Status Filter */}
        <Select value={clientStatus} onValueChange={setClientStatus}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Client Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="active">Active</SelectItem>
            <SelectItem value="pending">Pending</SelectItem>
            <SelectItem value="inactive">Inactive</SelectItem>
          </SelectContent>
        </Select>

        {/* Reset Filter Button */}
        <Button
          variant="ghost"
          onClick={handleResetFilters}
          className="ml-auto text-red-600 hover:text-red-700 hover:bg-red-50"
        >
          Reset Filter
        </Button>
      </div>

      <Card>
        <CardContent className="p-0 border rounded-lg shadow-none">
          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : error ? (
            <div className="text-center text-red-500 p-4">{error}</div>
          ) : verificationData.length === 0 ? (
            <div className="text-center text-gray-500 p-4">No verification history found</div>
          ) : (
            <DataTable 
              data={verificationData} 
              columns={columns}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}; 