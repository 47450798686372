import React from 'react';

interface SocialButtonProps {
  icon: React.ReactNode;
  label: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ icon, label }) => {
  return (
    <button
      type="button"
      className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-xl text-xs 
      shadow-sm font-medium text-gray-700 bg-white hover:bg-gray-50 gap-2"
    >
      {icon}
      <span className="hidden md:inline">{label}</span>
    </button>
  );
};

export default SocialButton; 