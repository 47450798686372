
interface FaceRecognitionIconProps {
    
}
 
const FaceRecognitionIcon: React.FC<FaceRecognitionIconProps> = () => {
    return ( 
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 4.91268V3.24601C1.5 2.32935 2.25 1.57935 3.16667 1.57935H4.83333M13.1667 1.57935H14.8333C15.75 1.57935 16.5 2.32935 16.5 3.24601V4.91268M16.5 13.246V14.9127C16.5 15.8293 15.75 16.5793 14.8333 16.5793H13.1667M4.83333 16.5793H3.16667C2.25 16.5793 1.5 15.8293 1.5 14.9127V13.246M5.66667 4.91268H9.83333C10.2936 4.91268 10.6667 5.28577 10.6667 5.74601V8.24601C10.6667 8.70625 10.2936 9.07935 9.83333 9.07935H5.66667C5.20643 9.07935 4.83333 8.70625 4.83333 8.24601V5.74601C4.83333 5.28577 5.20643 4.91268 5.66667 4.91268ZM8.16667 9.07935H12.3333C12.7936 9.07935 13.1667 9.45244 13.1667 9.91268V12.4127C13.1667 12.8729 12.7936 13.246 12.3333 13.246H8.16667C7.70643 13.246 7.33333 12.8729 7.33333 12.4127V9.91268C7.33333 9.45244 7.70643 9.07935 8.16667 9.07935Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}
 
export default FaceRecognitionIcon;