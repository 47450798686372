import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import LoginBackground from '../assets/login-background.png';
import { AuthLayout } from '../components/layouts/AuthLayout';
import { Button } from '../components/ui/button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../components/ui/form';
import { Input } from '../components/ui/input';
import Spinner from '../components/ui/spinner';
import { authService } from '../services/api.service';

const resetPasswordSchema = z.object({
  password: z.string().min(6, 'Password must be at least 6 characters'),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

type ResetPasswordForm = z.infer<typeof resetPasswordSchema>;

const resetPassword = async (data: ResetPasswordForm, token: string | null) => {
  if (!token) throw new Error('Reset token is missing');
  const response = await authService.resetPassword(token, data.password);
  if (!response.data.flag) {
    throw new Error(response.data.message);
  }
  return response.data;
};

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      toast.error('Reset token is missing');
      navigate('/login');
    }
  }, [token, navigate]);

  const form = useForm<ResetPasswordForm>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const mutation = useMutation({
    mutationFn: (values: ResetPasswordForm) => resetPassword(values, token),
    onSuccess: (response) => {
      if (response.flag) {
        toast.success('Password has been reset successfully');
        navigate('/login');
      } else {
        toast.error(response.message);
      }
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || error.message || 'Reset password failed';
      toast.error(errorMessage);
      if (errorMessage.toLowerCase().includes('token')) {
        navigate('/login');
      }
    },
  });

  const onSubmit = async (data: ResetPasswordForm) => {
    const result = await form.trigger();
    if (result) {
      mutation.mutate(data);
    }
  };

  return (
    <AuthLayout
      backgroundImage={LoginBackground}
      title="Reset Password"
      subtitle="Enter your new password below"
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>New Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Enter your new password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Confirm your new password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button 
            type="submit" 
            className="w-full mt-5"
            disabled={mutation.isPending}
          >
            {mutation.isPending ? (
              <div className="flex items-center justify-center gap-2">
                <Spinner />
              </div>
            ) : (
              "Reset Password"
            )}
          </Button>
        </form>
      </Form>
    </AuthLayout>
  );
};

export default ResetPassword; 