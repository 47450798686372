interface Tokens {
  token: string;
  refreshToken: string;
}

export const tokenService = {
  setTokens(tokens: Tokens) {
    localStorage.setItem('token', tokens.token);
    localStorage.setItem('refreshToken', tokens.refreshToken);
  },

  getTokens(): Tokens | null {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    
    if (token && refreshToken) {
      return { token, refreshToken };
    }
    return null;
  },

  clearTokens() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  },
}; 