import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

export const Settings = () => {
  return (
    <div className="space-y-4 fade-in">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold tracking-tight">Settings</h2>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>General Settings</CardTitle>
        </CardHeader>
        <CardContent>
          {/* Add settings content here */}
        </CardContent>
      </Card>
    </div>
  );
}; 