import { ReactNode } from 'react';

interface AuthLayoutProps {
  children: ReactNode;
  backgroundImage: string;
  title: string;
  subtitle?: string;
}

export const AuthLayout = ({
  children,
  backgroundImage,
  title,
  subtitle,
}: AuthLayoutProps) => {
  return (
    <div className="h-screen flex items-center justify-center bg-white overflow-hidden">
      <div className="flex bg-white w-full max-w-7xl mx-autu overflow-hidden">
          <div className="hidden lg:block lg:w-1/2">
          <img
            className="h-[711px] w-[488px] flex justify-center items-center p-5 object-cover"
            src={backgroundImage}
            alt="Authentication background"
          />
        </div>
        <div className="w-full lg:w-1/2 p-8 flex flex-col justify-center overflow-y-auto max-h-[714px] py-2 error-validation">
          <div className="w-full mx-auto max-h-[714px]">
            <div className="text-start mb-5">
              <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
              {subtitle && (
                <p className="mt-2 text-sm text-gray-600">{subtitle}</p>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}; 