interface GoogleIconProps {
    
}
 
const GoogleIcon: React.FC<GoogleIconProps> = () => {
    return ( 
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.8055 10.8748H21V10.8333H12V14.8333H17.6515C16.827 17.1618 14.6115 18.8333 12 18.8333C8.6865 18.8333 6 16.1468 6 12.8333C6 9.51981 8.6865 6.83331 12 6.83331C13.5295 6.83331 14.921 7.41031 15.9805 8.35281L18.809 5.52431C17.023 3.85981 14.634 2.83331 12 2.83331C6.4775 2.83331 2 7.31081 2 12.8333C2 18.3558 6.4775 22.8333 12 22.8333C17.5225 22.8333 22 18.3558 22 12.8333C22 12.1628 21.931 11.5083 21.8055 10.8748Z" fill="#FFC107"/>
<path d="M3.15295 8.17881L6.43845 10.5883C7.32745 8.38731 9.48045 6.83331 12 6.83331C13.5295 6.83331 14.921 7.41031 15.9805 8.35281L18.809 5.52431C17.023 3.85981 14.634 2.83331 12 2.83331C8.15895 2.83331 4.82795 5.00181 3.15295 8.17881Z" fill="#FF3D00"/>
<path d="M12 22.8333C14.583 22.8333 16.93 21.8448 18.7045 20.2373L15.6095 17.6183C14.6055 18.3788 13.3575 18.8333 12 18.8333C9.39903 18.8333 7.19053 17.1748 6.35853 14.8603L3.09753 17.3728C4.75253 20.6113 8.11353 22.8333 12 22.8333Z" fill="#4CAF50"/>
<path d="M21.8055 10.8748H21V10.8333H12V14.8333H17.6515C17.2555 15.9518 16.536 16.9163 15.608 17.6188L15.6095 17.6178L18.7045 20.2368C18.4855 20.4358 22 17.8333 22 12.8333C22 12.1628 21.931 11.5083 21.8055 10.8748Z" fill="#1976D2"/>
</svg>
     );
}
 
export default GoogleIcon;