import { SearchIcon } from "lucide-react";
import { useState } from 'react';
import { Sidebar } from '../components/Sidebar';
import { Input } from '../components/ui/input';
import { UserProfileDropdown } from '../components/UserProfileDropdown';
import { RouteId, routes } from '../config/routes';

export const Dashboard = () => {
  const [activeSection, setActiveSection] = useState<RouteId>('dashboard');

  const ActiveComponent = routes.find(route => route.id === activeSection)?.component;

  return (
    <div className="min-h-screen bg-[#F5F6FA] flex">
      <Sidebar
        routes={routes}
        activeItem={activeSection}
        onItemClick={setActiveSection}
      />
      
      <div className="flex-1">
        <nav className="h-16 bg-white">
          <div className="flex h-16 items-center pr-4 pl-12">
            <div className="flex items-center ml-64 relative">
              <Input 
                type="text" 
                placeholder="Search" 
                className="bg-[#F5F6FA] rounded-full w-96 pl-11 m-0" 
              />
              <SearchIcon className="w-4 h-4 absolute left-4 top-3" />
            </div>
            <div className="ml-auto flex items-center space-x-4">
              <UserProfileDropdown />
            </div>
          </div>
        </nav>

        <main className="flex-1 p-8 ml-64 fade-in">
          {ActiveComponent && <ActiveComponent />}
        </main>
      </div>
    </div>
  );
}; 