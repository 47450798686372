import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastProvider } from './components/providers/ToastProvider';
import { UserProvider } from './contexts/UserContext';
import { Dashboard } from './pages/Dashboard';
import { ForgotPassword } from './pages/ForgotPassword';
import { Login } from './pages/Login';
import ResendConfirmEmail from './pages/ResendConfirmEmail';
import ResetPassword from './pages/ResetPassword';
import { Signup } from './pages/Signup';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const token = localStorage.getItem('token');
  return token ? <>{children}</> : <Navigate to="/login" />;
};

const queryClient = new QueryClient();

function App() {
  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute> 
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/resendConfirmationEmail" element={<ResendConfirmEmail />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
          </Routes>
        </Router>
        <ToastProvider />
      </QueryClientProvider>
    </UserProvider>
  );
}

export default App;
