import { ReactNode } from "react"
import { cn } from "../../lib/utils"
import { Card, CardContent, CardHeader, CardTitle } from "./card"

interface MetricCardProps {
  title: string
  value: string
  icon?: ReactNode
  change?: string
  className?: string
}

export function MetricCard({ title, value, icon, change, className }: MetricCardProps) {
  return (
    <Card className={cn("", className)}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">
          {title}
        </CardTitle>
        {icon}
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
        {change && (
          <p className="text-xs text-muted-foreground">
            {change} from last month
          </p>
        )}
      </CardContent>
    </Card>
  )
} 