
interface VoltoxIconProps {
    
}
 
const VoltoxIcon: React.FC<VoltoxIconProps> = () => {
    return ( 
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.00441 14.0358C4.17876 13.7453 2.34403 13.1042 0.79248 12.0776C1.85087 15.4905 4.69519 17.4739 8.86802 17.4739C13.0409 17.4739 15.7853 15.5051 16.8579 12.1153C16.4146 12.3715 15.9595 12.6069 15.4919 12.8142C12.5142 14.1347 9.22384 14.5476 6.00441 14.0358Z" fill="#1C5257"/>
<path d="M1.10347 9.9947C1.18406 10.0627 1.26748 10.1279 1.3492 10.1947C1.21243 10.0829 1.39517 10.2284 1.44397 10.2638C1.60571 10.3807 1.77085 10.4925 1.9394 10.5987C2.29012 10.8207 2.65502 11.0207 3.02957 11.2005C3.11469 11.2415 3.19982 11.2814 3.28608 11.3196C3.30651 11.3286 3.42058 11.3786 3.46768 11.3994C3.51081 11.4163 3.6368 11.4674 3.65836 11.4758C3.73384 11.505 3.80988 11.5337 3.88593 11.5618C4.2287 11.6871 4.57714 11.7983 4.92899 11.895C5.23771 11.9798 5.55041 12.0529 5.86424 12.1169C5.31887 11.3196 5.07087 10.1936 5.07087 8.7816C5.07087 5.81545 6.22063 4.12532 8.8334 4.12532C11.4462 4.12532 12.5954 5.81545 12.5954 8.7816C12.5954 10.1616 12.3502 11.2685 11.8258 12.0624C12.311 11.9568 12.7923 11.8298 13.265 11.6781C13.6095 11.5674 13.95 11.4449 14.2854 11.3101C14.3206 11.296 14.3552 11.2814 14.3904 11.2674C14.4012 11.2623 14.4131 11.2572 14.4301 11.2499C14.5033 11.2173 14.5771 11.1853 14.6497 11.1522C14.808 11.0802 14.9647 11.0061 15.1202 10.9285C15.7768 10.6021 16.4078 10.2256 17.0043 9.80085C17.0803 9.74691 17.1552 9.69185 17.2301 9.63622C17.2438 9.62611 17.2568 9.61599 17.2704 9.60588C17.2812 9.59745 17.296 9.58621 17.309 9.5761C17.3238 9.32719 17.3334 9.07491 17.3334 8.81588C17.3334 3.36622 14.0238 0.192734 8.86802 0.192734C3.71227 0.192734 0.333374 3.36622 0.333374 8.81588C0.333374 8.96422 0.336778 9.1103 0.341318 9.25583C0.579668 9.5171 0.832774 9.76545 1.10404 9.99358L1.10347 9.9947Z" fill="#1C5257"/>
</svg>

     );
}
 
export default VoltoxIcon;