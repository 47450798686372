import { Brain, FileCheck, UserCircle, Users } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { MetricCard } from "../ui/metric-card";

export const DashboardOverview = () => {
  return (
    <div className="flex-1 space-y-4 fade-in">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">KYC & Age Verification Dashboard</h2>
        <div className="flex items-center space-x-2">
          <span className="flex h-2 w-2 rounded-full bg-green-500"></span>
          <span className="text-sm text-muted-foreground">Active</span>
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <MetricCard
          title="User Count"
          value="1,234"
          change="+5%"
          icon={<Users className="h-4 w-4 text-muted-foreground" />}
        />
        <MetricCard
          title="Age Verification"
          value="567"
          change="+3%"
          icon={<FileCheck className="h-4 w-4 text-muted-foreground" />}
        />
        <MetricCard
          title="KYC Verification"
          value="890"
          change="+4%"
          icon={<UserCircle className="h-4 w-4 text-muted-foreground" />}
        />
        <MetricCard
          title="Emotion Analysis"
          value="345"
          change="+2%"
          icon={<Brain className="h-4 w-4 text-muted-foreground" />}
        />
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
        <Card className="col-span-4">
          <CardHeader>
            <CardTitle>Gender & Race Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              {/* Add your chart component here */}
            </div>
          </CardContent>
        </Card>
        <Card className="col-span-3">
          <CardHeader>
            <CardTitle>Age Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              {/* Add your chart component here */}
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Suggested Filters</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex flex-col space-y-2">
            {['Age', 'Location', 'Gender', 'Race', 'Emotion'].map((filter, index) => (
              <div key={filter} className="flex justify-between items-center">
                <span className="text-sm text-muted-foreground">Filter by {filter}</span>
                <span className="text-sm">{5 - index}</span>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}; 